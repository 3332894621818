import {isFuture} from 'date-fns'
import {imageUrlFor} from '../lib/image-url'
import {siteUrl, siteName} from './config'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => ({
    ...edge.node,
    authors: fixAuthors(edge.node),
    categories: fixCategories(edge.node)
  }))
}

export function fixAuthors ({authors = []}) {
  return authors.map(node => node.author)
}

export function fixCategories ({_rawCategories}) {
  return (_rawCategories || []).map(node => ({id: node._id, slug: node.slug.current, title: node.title}))
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

export function getBlogUrl (publishedAt, slug) {
  return `/${slug.current || slug}/`
}

export function buildImageObj (source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText (blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

export function parseSanityPosts (posts = []) {
  return posts.map(post => parseSanityPost(post))
}

export function parseSanityPost (post) {
  return {
    lazyLoaded: true,
    id: post._id,
    publishedAt: post.publishedAt,
    title: post.title,
    slug: post.slug,
    _rawExcerpt: post.excerpt,
    mainImage: {
      ...post.mainImage,
      asset: {
        _id: post.mainImage.asset._ref
      }
    },
    authors: fixAuthors(post),
    categories: post.categories.map(node => ({id: node.id, slug: node.slug.current, title: node.title}))
  }
}

export function mergePosts (array1, array2) {
  const resultArray = []
  const arr = array1.concat(array2)
  let len = arr.length
  const assoc = {}

  while (len--) {
    const item = arr[len]

    if (!assoc[item.id]) {
      resultArray.unshift(item)
      assoc[item.id] = true
    }
  }

  return resultArray
}

export function countWords (text) {
  return text.length > 0
    ? text.replace(/(^\s*)|(\s*$)/gi, '') // exclude start and end white-space
      .replace(/[ ]{2,}/gi, ' ') // replace 2 spaces to 1
      .replace(/\n /, '\n') // exclude new lines
      .split(' ')
      .filter(n => n !== '')
      .length
    : 0
}

export function buildLinkedDataObj (post) {
  const author = post.authors.length > 0 ? post.authors[0].author : {}
  const category = post.categories.length > 0 ? post.categories[0] : {}
  const {_createdAt, publishedAt, _updatedAt, slug, title, _rawExcerpt, _rawBody, mainImage} = post
  const width = 1200
  const height = Math.floor((9 / 16) * width)
  const url = `${siteUrl}${getBlogUrl(publishedAt, slug)}`
  const imageUrl = imageUrlFor(buildImageObj(mainImage)).width(width).height(height).fit('crop').auto('format').url()

  return [
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': url,
            name: title,
            'image': imageUrl
          }
        }
      ]
    },
    {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': url
      },
      'publisher': {
        '@type': 'Organization',
        'name': siteName,
        'logo': ''
      },
      'headline': title,
      'image': imageUrl,
      'keywords': [],
      'genre': category.title ? category.title : '',
      'wordcount': `${countWords(toPlainText(_rawBody))}`,
      'url': url,
      'dateCreated': _createdAt,
      'datePublished': publishedAt,
      'dateModified': _updatedAt,
      'author': {'@type': 'Person', 'name': author.name, 'url': `${siteUrl}/author/${author.slug.current}`},
      'description': toPlainText(_rawExcerpt),
      'articleBody': toPlainText(_rawBody)
    }
  ]
}

export function calculateReadingTime (text) {
  const wordsPerMinute = 200
  const words = countWords(text)
  let readingTime = 0

  if (words > 0) {
    readingTime = Math.ceil(words / wordsPerMinute)
  }

  return readingTime
}

export function buildCanonical (slug) {
  return `${siteUrl}${slug}/`
}

export function insertTiktok () {
  const script = document.createElement('script')
  script.src = 'https://www.tiktok.com/embed.js'
  script.async = true
  document.body.appendChild(script)
}

export function validURL (str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

export function insertScripts (scripts) {
  scripts.map(script => {
    const element = document.createElement('script')
    element.src = script
    element.async = true
    document.body.appendChild(element)
  })
}
