import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

export default (props) => {
  const {style = 'normal'} = props.node

  if (!props.children) {
    return <></>
  }

  if (style === 'normal') {
    return <div className='flex flex-col flex-wrap mb-6 w-full'>{props.children}</div>
  }

  return BlockContent.defaultSerializers.types.block(props)
}
